<template>
  <VueFinalModal name="telegram" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen" @closed="onClosed">
    <div v-if="$auth.loggedIn" id="telegramModal" class="box-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('telegram')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">{{ $t("modal.telegram.title") }}</div>
      <div v-if="!$auth.user.socials.find((x) => x.provider === 'tg') || connect" class="robot-box">
        <div class="alert">
          {{ $t("modal.telegram.desc") }}
          <a target="_blank" :href="botLink">@{{ $config.public.TG_BOT }}</a>
        </div>
        <VButton target="_blank" :href="botLink" class="btn fcc">
          <FontIcon icon="telegram" size="15" class="mr5" />
          {{ $t("modal.telegram.bot") }}
        </VButton>
      </div>
      <div v-else class="robot-box">
        <div class="alert">
          {{ $t("modal.telegram.channel") }}
          <a target="_blank" :href="`tg://resolve?domain=${$config.public.TG_GROUP}`">Telegram</a>
        </div>
        <a target="_blank" :href="`tg://resolve?domain=${$config.public.TG_GROUP}`" class="btn fcc">
          <FontIcon icon="telegram" size="15" class="mr5" />
          {{ $t("modal.telegram.channel") }}
        </a>
        <button class="telegramGetBonus" @click="onCheckTelegram">{{ $t("modal.telegram.submit") }}</button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { $vfm, VueFinalModal } from "vue-final-modal";
import { storeToRefs } from "pinia";
import { useStore } from "~~/store";
import copier from "clipboard-copy";

export default {
  components: { VueFinalModal },
  setup() {
    const recaptcha = ref(false);
    const opened = ref(false);
    const connect = ref(false);

    const { t } = useI18n();

    const { $axios, $auth, $showToast, $config } = useNuxtApp();

    const form = ref({
      captcha: null,
    });
    const onCaptchaVerified = (e) => {
      form.value.captcha = e;
      onCheckTelegram();
    };
    const onBeforeOpen = (e) => {
      opened.value = true;
      connect.value = !!e.ref.params.value.connect;
    };
    const onClosed = () => {
      Object.assign(form.value, {
        captcha: null,
      });
    };

    const onCheckTelegram = async () => {
      if (!form.value.captcha)
        return $vfm.show("captcha", {
          verify: onCaptchaVerified,
        });
      await $axios.$post("/bonus/check/tg", form.value).then(({ response }) => {
        if (response?.balance) $auth.user.money = response.balance;
      });
    };

    const commandText = computed(() => {
      const token = $auth.user?.telegram_bind_code;
      if (!token) return t("modal.telegram.refresh");
      return `/bind ${token}`;
    });
    const botLink = computed(() => {
      const token = $auth.user?.telegram_bind_code;
      if (!token) return t("modal.telegram.refresh");
      return `https://t.me/${$config.public.TG_BOT}?start=${token}`;
    });

    const copyCommand = () => {
      copier(commandText.value);
      $showToast(t("modal.telegram.copied"), "success");
    };
    const storeIndex = useStore();
    const { dark } = storeToRefs(storeIndex);

    return {
      recaptcha,
      form,
      opened,
      copyCommand,
      onBeforeOpen,
      onCaptchaVerified,
      onCheckTelegram,
      onClosed,
      commandText,
      botLink,
      connect,
      dark,
    };
  },
};
</script>
